import { Injectable } from '@angular/core';
import { combineLatestWith, Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeliveryType } from '../../api/v1/models/delivery-type';
import { SessionRepository } from '../../shared/repositories/session.repository';

@Injectable({
  providedIn: 'root'
})
export class OrderDeliveryModalService {
  constructor(
    private sessionRepository: SessionRepository,
  ) {}

  private _showOrderTypeModalSubj$: Subject<unknown> = new Subject<unknown>();
  get showOrderTypeModal$(): Observable<unknown>{
    return this._showOrderTypeModalSubj$.asObservable();
  }
  showOrderTypeModal(){
    this._showOrderTypeModalSubj$.next(null)
  }


  private _checkOrderTypeModalSubj$: ReplaySubject<unknown> = new ReplaySubject<unknown>(1);
  get checkOrderTypeModal$(): Observable<unknown>{
    return this._checkOrderTypeModalSubj$.asObservable();
  }
  checkOrderTypeModal(){
    this._checkOrderTypeModalSubj$.next(null)
  }

  isCheckOrderTypeModal$: Observable<boolean> = this.checkOrderTypeModal$.pipe(
    combineLatestWith(
      this.sessionRepository.selectedDeliveryType$,
      this.sessionRepository.deliveryAddress$,
    ),
    map(([checkOrderTypeModalSubj, selectedDeliveryType, deliveryAddress]) => {
      if(selectedDeliveryType === DeliveryType.Pickup){
        return false;
      }

      if(selectedDeliveryType === DeliveryType.Delivery && deliveryAddress){
        return false;
      }

      return true;
    })
  )
}
