/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AuthCodeResponseGenericResponse } from '../models/auth-code-response-generic-response';
import { AuthenticatedRequestDto } from '../models/authenticated-request-dto';
import { BasketDtoGenericResponse } from '../models/basket-dto-generic-response';
import { BurnSessionRequestDto } from '../models/burn-session-request-dto';
import { CustomerProfileDto } from '../models/customer-profile-dto';
import { CustomerProfileDtoGenericResponse } from '../models/customer-profile-dto-generic-response';
import { OrderHistoryDtoGenericResponse } from '../models/order-history-dto-generic-response';
import { OrderInfoRequest } from '../models/order-info-request';
import { OrdersHistoryAuthenticatedRequestDto } from '../models/orders-history-authenticated-request-dto';
import { ProfileRequestDto } from '../models/profile-request-dto';
import { RequestAuthCodeDto } from '../models/request-auth-code-dto';
import { RequestRegisterCustomerDto } from '../models/request-register-customer-dto';

@Injectable({ providedIn: 'root' })
export class CustomerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1CustomerRequestAuthCodePost()` */
  static readonly V1CustomerRequestAuthCodePostPath = '/v1/customer/request-auth-code';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerRequestAuthCodePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerRequestAuthCodePost$Plain$Response(
    params?: {
      body?: RequestAuthCodeDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AuthCodeResponseGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerRequestAuthCodePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthCodeResponseGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerRequestAuthCodePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerRequestAuthCodePost$Plain(
    params?: {
      body?: RequestAuthCodeDto
    },
    context?: HttpContext
  ): Observable<AuthCodeResponseGenericResponse> {
    return this.v1CustomerRequestAuthCodePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthCodeResponseGenericResponse>): AuthCodeResponseGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerRequestAuthCodePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerRequestAuthCodePost$Json$Response(
    params?: {
      body?: RequestAuthCodeDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AuthCodeResponseGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerRequestAuthCodePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthCodeResponseGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerRequestAuthCodePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerRequestAuthCodePost$Json(
    params?: {
      body?: RequestAuthCodeDto
    },
    context?: HttpContext
  ): Observable<AuthCodeResponseGenericResponse> {
    return this.v1CustomerRequestAuthCodePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthCodeResponseGenericResponse>): AuthCodeResponseGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1CustomerRegisterPost()` */
  static readonly V1CustomerRegisterPostPath = '/v1/customer/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerRegisterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerRegisterPost$Plain$Response(
    params?: {
      body?: RequestRegisterCustomerDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AuthCodeResponseGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthCodeResponseGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerRegisterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerRegisterPost$Plain(
    params?: {
      body?: RequestRegisterCustomerDto
    },
    context?: HttpContext
  ): Observable<AuthCodeResponseGenericResponse> {
    return this.v1CustomerRegisterPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthCodeResponseGenericResponse>): AuthCodeResponseGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerRegisterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerRegisterPost$Json$Response(
    params?: {
      body?: RequestRegisterCustomerDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AuthCodeResponseGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthCodeResponseGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerRegisterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerRegisterPost$Json(
    params?: {
      body?: RequestRegisterCustomerDto
    },
    context?: HttpContext
  ): Observable<AuthCodeResponseGenericResponse> {
    return this.v1CustomerRegisterPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthCodeResponseGenericResponse>): AuthCodeResponseGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1CustomerSessionAuthPost()` */
  static readonly V1CustomerSessionAuthPostPath = '/v1/customer/session/auth';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerSessionAuthPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerSessionAuthPost$Plain$Response(
    params?: {
      body?: ProfileRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CustomerProfileDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerSessionAuthPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerProfileDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerSessionAuthPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerSessionAuthPost$Plain(
    params?: {
      body?: ProfileRequestDto
    },
    context?: HttpContext
  ): Observable<CustomerProfileDtoGenericResponse> {
    return this.v1CustomerSessionAuthPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerProfileDtoGenericResponse>): CustomerProfileDtoGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerSessionAuthPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerSessionAuthPost$Json$Response(
    params?: {
      body?: ProfileRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CustomerProfileDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerSessionAuthPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerProfileDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerSessionAuthPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerSessionAuthPost$Json(
    params?: {
      body?: ProfileRequestDto
    },
    context?: HttpContext
  ): Observable<CustomerProfileDtoGenericResponse> {
    return this.v1CustomerSessionAuthPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerProfileDtoGenericResponse>): CustomerProfileDtoGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1CustomerSessionFetchPost()` */
  static readonly V1CustomerSessionFetchPostPath = '/v1/customer/session/fetch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerSessionFetchPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerSessionFetchPost$Plain$Response(
    params?: {
      body?: AuthenticatedRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CustomerProfileDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerSessionFetchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerProfileDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerSessionFetchPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerSessionFetchPost$Plain(
    params?: {
      body?: AuthenticatedRequestDto
    },
    context?: HttpContext
  ): Observable<CustomerProfileDtoGenericResponse> {
    return this.v1CustomerSessionFetchPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerProfileDtoGenericResponse>): CustomerProfileDtoGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerSessionFetchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerSessionFetchPost$Json$Response(
    params?: {
      body?: AuthenticatedRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CustomerProfileDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerSessionFetchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerProfileDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerSessionFetchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerSessionFetchPost$Json(
    params?: {
      body?: AuthenticatedRequestDto
    },
    context?: HttpContext
  ): Observable<CustomerProfileDtoGenericResponse> {
    return this.v1CustomerSessionFetchPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerProfileDtoGenericResponse>): CustomerProfileDtoGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1CustomerUpdatePost()` */
  static readonly V1CustomerUpdatePostPath = '/v1/customer/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerUpdatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerUpdatePost$Plain$Response(
    params?: {
      body?: CustomerProfileDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CustomerProfileDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerUpdatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerProfileDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerUpdatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerUpdatePost$Plain(
    params?: {
      body?: CustomerProfileDto
    },
    context?: HttpContext
  ): Observable<CustomerProfileDtoGenericResponse> {
    return this.v1CustomerUpdatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerProfileDtoGenericResponse>): CustomerProfileDtoGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerUpdatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerUpdatePost$Json$Response(
    params?: {
      body?: CustomerProfileDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CustomerProfileDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerUpdatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerProfileDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerUpdatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerUpdatePost$Json(
    params?: {
      body?: CustomerProfileDto
    },
    context?: HttpContext
  ): Observable<CustomerProfileDtoGenericResponse> {
    return this.v1CustomerUpdatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerProfileDtoGenericResponse>): CustomerProfileDtoGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1CustomerSessionBurnPost()` */
  static readonly V1CustomerSessionBurnPostPath = '/v1/customer/session/burn';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerSessionBurnPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerSessionBurnPost$Response(
    params?: {
      body?: BurnSessionRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerSessionBurnPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerSessionBurnPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerSessionBurnPost(
    params?: {
      body?: BurnSessionRequestDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.v1CustomerSessionBurnPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1CustomerOrdersHistoryPost()` */
  static readonly V1CustomerOrdersHistoryPostPath = '/v1/customer/orders/history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerOrdersHistoryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerOrdersHistoryPost$Plain$Response(
    params?: {
      body?: OrdersHistoryAuthenticatedRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OrderHistoryDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerOrdersHistoryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderHistoryDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerOrdersHistoryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerOrdersHistoryPost$Plain(
    params?: {
      body?: OrdersHistoryAuthenticatedRequestDto
    },
    context?: HttpContext
  ): Observable<OrderHistoryDtoGenericResponse> {
    return this.v1CustomerOrdersHistoryPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderHistoryDtoGenericResponse>): OrderHistoryDtoGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerOrdersHistoryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerOrdersHistoryPost$Json$Response(
    params?: {
      body?: OrdersHistoryAuthenticatedRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OrderHistoryDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerOrdersHistoryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderHistoryDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerOrdersHistoryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerOrdersHistoryPost$Json(
    params?: {
      body?: OrdersHistoryAuthenticatedRequestDto
    },
    context?: HttpContext
  ): Observable<OrderHistoryDtoGenericResponse> {
    return this.v1CustomerOrdersHistoryPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderHistoryDtoGenericResponse>): OrderHistoryDtoGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1CustomerOrdersPost()` */
  static readonly V1CustomerOrdersPostPath = '/v1/customer/orders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerOrdersPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  v1CustomerOrdersPost$Plain$Response(
    params?: {
      body?: OrderInfoRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OrderHistoryDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerOrdersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderHistoryDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerOrdersPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  v1CustomerOrdersPost$Plain(
    params?: {
      body?: OrderInfoRequest
    },
    context?: HttpContext
  ): Observable<OrderHistoryDtoGenericResponse> {
    return this.v1CustomerOrdersPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderHistoryDtoGenericResponse>): OrderHistoryDtoGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerOrdersPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  v1CustomerOrdersPost$Json$Response(
    params?: {
      body?: OrderInfoRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OrderHistoryDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerOrdersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderHistoryDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerOrdersPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   *
   * @deprecated
   */
  v1CustomerOrdersPost$Json(
    params?: {
      body?: OrderInfoRequest
    },
    context?: HttpContext
  ): Observable<OrderHistoryDtoGenericResponse> {
    return this.v1CustomerOrdersPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderHistoryDtoGenericResponse>): OrderHistoryDtoGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1CustomerOrdersBasketBasketIdPost()` */
  static readonly V1CustomerOrdersBasketBasketIdPostPath = '/v1/customer/orders/basket/{basketId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerOrdersBasketBasketIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerOrdersBasketBasketIdPost$Plain$Response(
    params: {
      basketId: string;
      body?: AuthenticatedRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BasketDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerOrdersBasketBasketIdPostPath, 'post');
    if (params) {
      rb.path('basketId', params.basketId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BasketDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerOrdersBasketBasketIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerOrdersBasketBasketIdPost$Plain(
    params: {
      basketId: string;
      body?: AuthenticatedRequestDto
    },
    context?: HttpContext
  ): Observable<BasketDtoGenericResponse> {
    return this.v1CustomerOrdersBasketBasketIdPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BasketDtoGenericResponse>): BasketDtoGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1CustomerOrdersBasketBasketIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerOrdersBasketBasketIdPost$Json$Response(
    params: {
      basketId: string;
      body?: AuthenticatedRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BasketDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomerService.V1CustomerOrdersBasketBasketIdPostPath, 'post');
    if (params) {
      rb.path('basketId', params.basketId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BasketDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1CustomerOrdersBasketBasketIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1CustomerOrdersBasketBasketIdPost$Json(
    params: {
      basketId: string;
      body?: AuthenticatedRequestDto
    },
    context?: HttpContext
  ): Observable<BasketDtoGenericResponse> {
    return this.v1CustomerOrdersBasketBasketIdPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BasketDtoGenericResponse>): BasketDtoGenericResponse => r.body)
    );
  }

}
