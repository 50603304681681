import { Injectable } from '@angular/core';
import { createState, Store } from '@ngneat/elf';
import {
  deleteAllEntities,
  deleteEntities,
  getAllEntities,
  getEntitiesCount,
  selectAllEntities,
  updateAllEntities,
  updateEntities,
  upsertEntitiesById,
  withEntities
} from '@ngneat/elf-entities';
import { Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProcessBasketResponse } from '../../api/v1/models/process-basket-response';
import { BasketItem } from '../interfaces/basket-item.interface';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

const { state: basketState, config: basketStateConfig } = createState(
  withEntities<BasketItem, 'itemKey'>({ idKey: 'itemKey' }),
)
const basketStore = new Store({
  state: basketState,
  name: 'BasketStore',
  config: basketStateConfig
});

persistState(basketStore, {
	key: 'basketState',
	storage: localStorageStrategy,
});

@Injectable({ providedIn: 'root' })
export class BasketRepository {
  constructor() { }

  items$: Observable<BasketItem[]> = basketStore.pipe(
    selectAllEntities(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  get items() {
    return basketStore.query(getAllEntities());
  }

  isItemInBasket(uniqKey: string): boolean {
    const items = this.items;
    for(let i = 0; i < items.length; i++){
      if(uniqKey === items[i].item.uniqKey){
        return true;
      }
    }
    return false
  }

  getBasketItem$(uniqKey: string): Observable<BasketItem | null> {
    return this.items$.pipe(
      map(basketItems => {
        const item = basketItems.find(basketItem => basketItem.item.uniqKey === uniqKey)
        return item ? item : null;
      })
    )
  }

  itemsAmount$: Observable<number> = this.items$.pipe(map(basketItems => basketItems.length));

  get totalCount(): number {
    return basketStore.query(getEntitiesCount());
  }

  addItem(item: BasketItem): void {
    const itemKey = this.getItemKey(item);

    basketStore.update(
      upsertEntitiesById(itemKey, {
        updater: (element: BasketItem) => ({ ...element, count: element.count + item.count }),
        creator: () => ({ ...item, itemKey: itemKey, appliedDiscount: 0 }),
      })
    );
  }

  removeItem(itemKey: string | string[]): void {
    basketStore.update(deleteEntities(itemKey));
  }

  updateCount(itemKey: string, count: number): void {
    basketStore.update(updateEntities(itemKey, (entity) => ({ ...entity, count })));
  }

  private getItemKey(item: BasketItem): string {
    let itemKey = '';

    itemKey += item.item.uniqKey;
    itemKey += item.size.sku;

    if (!item.modifiers) { return itemKey; }

    item.modifiers.forEach(modifier => {
      if (!modifier) { return }
      itemKey += modifier.sku + modifier.quantity.toString();
    });

    return itemKey;
  }

  updateAll(data: ProcessBasketResponse) {
    basketStore.update(
      updateAllEntities((entity) => ({
        ...entity,
        appliedDiscount: data.itemsDiscounts ? data.itemsDiscounts[entity.itemKey] : entity.appliedDiscount,
      }))
    );
  }

  deleteAll() {
    basketStore.update(deleteAllEntities());
  }
}
