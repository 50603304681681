import { Injectable } from '@angular/core';
import { ConfigRepository } from './config.repository';
import { BasketRepository } from './basket.repository';
import { MenuListRepository } from './menu-list.repository';
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  Observable,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
  tap
} from 'rxjs';
import {
  CostRule,
  CustomerDto,
  CustomerProfileDto,
  DeliveryType,
  FreeItem,
  MenuItemSizeDto,
  OrderItemDto,
  Outlet,
  OutletMode,
  PastOrderItemDto,
  PaymentType,
  ProcessBasketRequestDto,
  ProcessBasketResponse,
  RelatedProduct,
  StoredOrderItem
} from 'src/app/api/v1/models';
import { OrderService } from 'src/app/api/v1/services';
import { BasketItem } from '../interfaces/basket-item.interface';
import { StatusMessageService } from '../services/status-message.service';
import { createState, deepFreeze, elfHooks, select, setProp, Store, withProps } from '@ngneat/elf';
import {
  selectIsRequestPending,
  selectRequestStatus,
  updateRequestStatus,
  withRequestsStatus
} from '@ngneat/elf-requests';
import { UIMenuItemDto } from '../interfaces/ui-menu-item-dto.interface';
import { Router } from '@angular/router';
import { Modifier } from '../interfaces/modifier.interface';
import { ErrorService } from '../services/error.service';
import { excludeKeys, localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { environment } from 'src/environments/environment';
import { AddressDto } from '../../api/v1/models/address-dto';

const requiredDeliveryTypesToOutletModes: { [key: string]: DeliveryType[] } = {
	'PICKUP': [DeliveryType.Pickup],
	'DELIVERY': [DeliveryType.Delivery],
	'PICKUP_DELIVERY': [DeliveryType.Pickup, DeliveryType.Delivery],
	'EATIN': [DeliveryType.Eatin],
}
interface SessionStateModel {
	selectedDeliveryType: DeliveryType | null,
	preprocessedBasketId: string | null,
	balanceToApply: Map<string, number>,
	basket: ProcessBasketRequestDto | null,
	isAuth: boolean,
	customer: CustomerProfileDto | null,
	isBonusPayment: boolean,
	bonusPaymentSum: number | null,
	isMustProcess: boolean,
	maxBonusPayment: number | null,
	exchangeRate: number | null,
	onlinePaymentOrderId: string | null,
	tablePosId: string | null,
	tableId: string | null,
	isTableFromLink: boolean,
	numberOfGuests: number,
	dtmTime: string | null,
  deliveryAddress: AddressDto | null,
  freeItems: FreeItem[] | null,
}

const { state: sessionState, config: sessionStateConfig } = createState(
	withProps<SessionStateModel>({
    selectedDeliveryType: null,
    preprocessedBasketId: null,
    balanceToApply: new Map<string, number>(),
    basket: null,
    isAuth: false,
    customer: null,
    isBonusPayment: false,
    bonusPaymentSum: null,
    isMustProcess: true,
    maxBonusPayment: null,
    exchangeRate: null,
    onlinePaymentOrderId: null,
    tablePosId: null,
    tableId: null,
    isTableFromLink: false,
    numberOfGuests: 1,
    dtmTime: null,
    deliveryAddress: null,
    freeItems: null,
  }),
  withRequestsStatus<'basketPreprocessing'>(),
	withRequestsStatus<'couponStatusFetching'>(),
)
const sessionStore = new Store({ state: sessionState, name: 'SessionStore', config: sessionStateConfig });

persistState(sessionStore, {
	key: 'sessionState',
	storage: localStorageStrategy,
	source: () => sessionStore.pipe(excludeKeys(['basket', 'requestsStatus', 'freeItems'])),
});

if (!environment.production) {
	elfHooks.registerPreStateInit(initialState => deepFreeze(initialState));
}

@Injectable({ providedIn: 'root' })
export class SessionRepository {
	constructor(
		private configRepository: ConfigRepository,
		private basketRepository: BasketRepository,
		private menuRepository: MenuListRepository,
		private orderService: OrderService,
		private statusMessage: StatusMessageService,
		private router: Router,
		private errorService: ErrorService,
	) {
		/**
 * Определяем начальный тип доставки на основании OutletMode;
 * Проверяем вхождение текущего DeliveryType в возможность работы с текущим OutletMode;
 */
		const initialDeliveryTypeSub = combineLatest([
			this.configRepository.outletMode$,
			this.selectedDeliveryType$,
		]).pipe(
			first(),
			map(([outletMode, selectedDeliveryType]) => {
				if (!outletMode) { return null }

				const currentConfig = requiredDeliveryTypesToOutletModes[outletMode];
				if (currentConfig && selectedDeliveryType && currentConfig.indexOf(selectedDeliveryType) < 0) {
					selectedDeliveryType = null
				}

				if (outletMode === OutletMode.Eatin) {
					return DeliveryType.Eatin
				}

				if (selectedDeliveryType) { return selectedDeliveryType }

				if (outletMode === OutletMode.Delivery || outletMode === OutletMode.PickupDelivery) {
					return DeliveryType.Delivery
				}

				return DeliveryType.Pickup;
			})
		).subscribe(initialType => {
      this.setDeliveryType(initialType);
      initialDeliveryTypeSub.unsubscribe();
    })
  }

	isBasketProcessing$ = sessionStore.pipe(selectRequestStatus('basketPreprocessing'));
	isCouponStatusFetching$ = sessionStore.pipe(selectIsRequestPending('couponStatusFetching'));
	preprocessedBasketId$: Observable<string | null> = sessionStore.pipe(select(state => state.preprocessedBasketId));
	isMustProcess$: Observable<boolean> = sessionStore.pipe(select(state => state.isMustProcess));
	isBonusPayment$: Observable<boolean> = sessionStore.pipe(select(state => state.isBonusPayment));
	maxBonusPayment$: Observable<number | null> = sessionStore.pipe(select(state => state.maxBonusPayment));
	exchangeRate$: Observable<number | null> = sessionStore.pipe(select(state => state.exchangeRate));
	basket$: Observable<ProcessBasketRequestDto | null> = sessionStore.pipe(map(source => source.basket));
	currentTablePosId$: Observable<string | null> = sessionStore.pipe(select(state => state.tablePosId));
	isTableFromLink$: Observable<boolean> = sessionStore.pipe(select(state => state.isTableFromLink));
	DTMTime$: Observable<string | null> = sessionStore.pipe(select(state => state.dtmTime));

	setDTMTime(time: string | null) {
		sessionStore.update(setProp('dtmTime', time));
	}

	setSelectedTablePosId(tablePosId: string | null, isTableFromLink: boolean = false) {
		sessionStore.update(state => ({ ...state, tablePosId, isTableFromLink }));
	}

	clearSelectedTable() {
		sessionStore.update(state => ({ ...state, tablePosId: null, isTableFromLink: false }));
	}

	setSelectedTableId(tableId: string | null) {
		sessionStore.update(state => ({ ...state, tableId }));
	}

	updateExchangeRate(exchangeRate: number): void {
		sessionStore.update(state => ({ ...state, exchangeRate }));
	}

	setIsProcess(isMustProcess: boolean): void {
		sessionStore.update(state => ({ ...state, isMustProcess }));
	}

	setNumberOfGuest(numberOfGuests: number): void {
		sessionStore.update(state => ({ ...state, numberOfGuests }));
	}

	setBonusPayment(value: boolean): void {
		sessionStore.update(state => ({
			...state,
			isBonusPayment: value,
			bonusPaymentSum: value ? state.maxBonusPayment : 0,
			//TODO: заменить на флаг, после ec-1000
			basket: { ...state.basket, bonusPaymentSum: value ? state.maxBonusPayment : 0 } as ProcessBasketRequestDto
		}));
	}

	get guestNumberCount(): number {
		return sessionStore.query(state => state.numberOfGuests)
	}

	get onlinePaymentOrderId(): string | null {
		return sessionStore.query(state => state.onlinePaymentOrderId);
	}
	setOnlinePaymentOrderId(id: string | null) {
		sessionStore.update(setProp('onlinePaymentOrderId', id))
	}

	get preprocessedBasketId(): string | null {
		return sessionStore.query(state => state.preprocessedBasketId);
	}

	setPreprocessBasketId(basketId: string | null) {
		sessionStore.update(setProp('preprocessedBasketId', basketId));
	}

	get tableId() {
		return sessionStore.query(state => state.tableId);
	}

	private basketChanged$ = this.basket$.pipe(
		pairwise(),
		map(([previous, current]) => previous != current),
		distinctUntilChanged()
	);

	private itemsChanged$ = this.basketRepository.items$.pipe(
		map(items => items.map(i => `i.itemKey|${i.count}`).join('|')),
		pairwise(),
		map(([previous, current]) => previous != current),
		distinctUntilChanged()
	);

	processBasketOnChange(): Observable<ProcessBasketResponse> {
		return combineLatest([
			this.basketChanged$.pipe(startWith(false)),
			this.itemsChanged$.pipe(startWith(false)),
		]).pipe(
			filter(([basketChanged, itemsChanged]) => basketChanged || itemsChanged),
			switchMap(() => this.processBasket())
		)
	}

	private processPendingTimeout: ReturnType<typeof setTimeout>;

	processBasket(): Observable<any> {
		return combineLatest([
			this.basket$,
			this.basketRepository.items$,
			this.preprocessedBasketId$,
			this.configRepository.paymentsTypes$,
		]).pipe(
			first(),
			filter(([basket, basketItems, basketId, paymentsTypes]) => {
				return !!paymentsTypes && !!basket && basketItems && !!basketItems.length;
			}),
			tap(() => {
				/*
				* updateRequestStatus странно себя ведет. Срабатывает дважды и во второй раз проставляет значение,
				* которое должно подставляться в subscribe.
				* Есть подозрение, что он каким-то способом смотрит поток, в котором находится
				* и на его основе сам ставит значения.
				* */
				this.processPendingTimeout = setTimeout(() =>
					sessionStore.update(updateRequestStatus('basketPreprocessing', 'pending')),
					25
				);
			}),

			switchMap(([basket, basketItems, preprocessedBasketId]) => {
				return this.orderService.v1OrderBasketProcessPost$Json({
					body: {
						...basket!,
						id: preprocessedBasketId,
						items: this.prepareProcessItems(basketItems)
					}
				})
			}),

			map(data => {
				if (data.error || !data.result) {
					throw data;
				}
				return data.result;
			}),

			tap(data => {
				this.updateAll(data);

				const basketId = sessionStore.query(state => state.preprocessedBasketId);
				if (!basketId) {
					sessionStore.update(setProp('preprocessedBasketId', data.basketId));
				}

				if (basketId && basketId !== data.basketId) {
					console.error('basketIds do not match');
				}

        sessionStore.update(setProp('freeItems', data.freeItems || null));
			}),

			catchError(error => {
				this.statusMessage.setStatusMessage({
					title: $localize`:@@MessageModalTitleError:Error`,
					message: $localize`:@@ResponseErrorSomethingElse:Something went wrong...`,
					type: 'error'
				});
				sessionStore.update(updateRequestStatus('basketPreprocessing', 'error', error));

				throw error;
			}),

			finalize(() => {
				clearTimeout(this.processPendingTimeout)
				sessionStore.update(updateRequestStatus('basketPreprocessing', 'success'));
			}),
		)
	}

	private prepareProcessItems(basketItems: BasketItem[]): OrderItemDto[] {
		const items: OrderItemDto[] = [];
		basketItems.forEach(basketItem => {
			const orderItem: OrderItemDto = {} as OrderItemDto;
			orderItem.sku = basketItem.size ? basketItem.size.sku : basketItem.item.sku;
			orderItem.quantity = basketItem.count;
			orderItem.modifiers = basketItem.modifiers;
			orderItem.itemKey = basketItem.itemKey;

			orderItem.categoryId = basketItem.item.categoryId;
			orderItem.itemId = basketItem.item.itemId;

			items.push(orderItem);
		})

		return items;
	}

	basketRelatedItems$: Observable<UIMenuItemDto[]> = this.basketRepository.items$.pipe(
		switchMap(basketItems => {
			let relatedProducts: RelatedProduct[] = [];

			basketItems.forEach(basketItem => {
				relatedProducts = relatedProducts.concat(basketItem.size.relatedProducts)
			})

			return this.menuRepository.getRelatedProducts(relatedProducts);
		})
	)

	isDelivery$: Observable<boolean> = sessionStore.pipe(
		select(state => state.selectedDeliveryType === DeliveryType.Delivery),
		shareReplay({ refCount: false, bufferSize: 1 }),
	);

	get isDelivery(): boolean {
		return sessionStore.query((state) => state.selectedDeliveryType === DeliveryType.Delivery);
	}

	selectedDeliveryType$: Observable<DeliveryType | null> = sessionStore.pipe(
		select((state) => state.selectedDeliveryType)
	);

  get selectedDeliveryType(): DeliveryType | null {
    return sessionStore.query(state => state.selectedDeliveryType)
  }

	totalDiscount$: Observable<number> = this.basketRepository.items$.pipe(
		map((basketItems) => basketItems.reduce((sum, item) => sum + (item.appliedDiscount || 0), 0))
	);

	totalCount$: Observable<number> = this.basketRepository.items$.pipe(
		map(basketItems => basketItems.reduce((sum, current) => sum + current.count, 0))
	);

	totalPrice$: Observable<number> = combineLatest([
		this.basketRepository.items$,
		this.configRepository.activeOutletId$
	]).pipe(
		map(([basketItems, activeOutletId]) => {
			if (!activeOutletId) { return 0 }
			return basketItems.reduce((sum, item) => {
				return sum + this.getItemTotal(item);
			}, 0)
		}),
		shareReplay({ refCount: true, bufferSize: 1 }),
	);

	getItemTotal(item: BasketItem): number {
		const outletId = this.configRepository.activeOutletId;
		const itemPrice = item.size.prices.find(p => p.storeId == outletId);
		if (!itemPrice) { return 0; }

		let itemTotal = itemPrice.price ?? 0;
		item.modifiers.forEach(mod => {
			if (!mod || !mod.prices) { return }
			const modPrice = mod.prices.find(p => p.storeId == outletId);
			if (modPrice && modPrice.price) {
				itemTotal! += modPrice.price * mod.quantity;
			}

			if (modPrice && modPrice.price && mod.freeQuantity) {
				if (mod.freeQuantity >= mod.quantity) {
					itemTotal = itemTotal - (modPrice.price * mod.quantity);
				} else {
					itemTotal = itemPrice.price + modPrice.price * (mod.quantity - mod.freeQuantity);
				}
			}
		});
		return itemTotal * item.count;
	}

	actualCostRule$: Observable<CostRule | null> = combineLatest([
		this.totalPrice$,
		this.totalDiscount$,
		this.configRepository.activeCostRules$,
	]).pipe(
		map(([totalPrice, totalDiscount, costRules]) => {
			if (!costRules || !costRules.length) { return null }

			for (let i = 0; i < costRules.length; i++) {
				if (!costRules[i].maxSum) {
					costRules[i].maxSum = Infinity;
				}
				if ((totalPrice - totalDiscount) < (costRules[i].maxSum as number)) {
					return costRules[i];
				}
			}

			return null;
		}),
		shareReplay({ refCount: true, bufferSize: 1 }),
	);

	minimalOrderPrice$: Observable<number> = this.configRepository.activeCostRules$.pipe(
		map(rules => {
			if (!rules || !rules.length) { return 0 }
			return rules[0].minSum || 0;
		}));

	deliveryPrice$: Observable<number> = this.actualCostRule$.pipe(map(rule => rule?.cost || 0));

	isFreeDelivery$: Observable<boolean> = this.actualCostRule$.pipe(map((cost) => !cost));

	actualDeliveryPrice$: Observable<number> = combineLatest([this.deliveryPrice$, this.isDelivery$, this.isFreeDelivery$])
		.pipe(
			map(([deliveryPrice, isDelivery, isFreeDelivery]) => isFreeDelivery || !isDelivery ? 0 : deliveryPrice)
		);

	sumToPay$: Observable<number> = combineLatest([
		this.totalPrice$,
		this.actualDeliveryPrice$,
		this.isFreeDelivery$,
		this.isDelivery$,
		this.totalDiscount$,
	]).pipe(
		map(([totalPrice, actualDeliveryPrice, isFreeDelivery, isDelivery, totalDiscount]) => {
			return totalPrice
				+ (isDelivery && !isFreeDelivery ? actualDeliveryPrice : 0)
				- (totalDiscount ? totalDiscount : 0);
		})
	);

	maxBonusPaymentSum$: Observable<number | null> = combineLatest([
		this.sumToPay$,
		this.maxBonusPayment$,
		this.exchangeRate$
	]).pipe(map(([sumToPay, maxBonusPayment, exchangeRate]) => {
		if (exchangeRate && exchangeRate >= 1) {
			return Math.min(sumToPay * exchangeRate, maxBonusPayment ?? 0);
		}
		return Math.min(sumToPay, maxBonusPayment ?? 0);
	}));

	maxBonusPaymentCurrencySum$: Observable<number | null> = combineLatest([
		this.sumToPay$,
		this.maxBonusPayment$,
		this.exchangeRate$
	]).pipe(
		map(([sumToPay, maxBonusPayment, exchangeRate]) => exchangeRate && exchangeRate >= 1
			? Math.min(sumToPay, (maxBonusPayment ?? 0) / exchangeRate)
			: Math.min(sumToPay, maxBonusPayment ?? 0)
		));

	isMinimalOrderPrice$: Observable<boolean> = combineLatest([
		this.minimalOrderPrice$,
		this.totalPrice$,
		this.totalDiscount$,
	]).pipe(map(([minDeliveryOrderPrice, totalPrice, discount]) => minDeliveryOrderPrice <= (totalPrice - discount)));

	applicableOutlets$: Observable<Outlet[]> = combineLatest([
		this.configRepository.outlets$,
		this.selectedDeliveryType$,
	]).pipe(map(([outlets, selectedDeliveryType]) => {
		switch (selectedDeliveryType) {
			case DeliveryType.Delivery:
				return outlets.filter(outlet =>
					outlet.outletMode == OutletMode.Delivery || outlet.outletMode == OutletMode.PickupDelivery);
			case DeliveryType.Pickup:
				return outlets.filter(outlet =>
					outlet.outletMode == OutletMode.Pickup || outlet.outletMode == OutletMode.PickupDelivery);
			default:
				return [] as Outlet[];
		}
	}));

	get hasUnappliedBalance() {
		return sessionStore.query((state) => state.balanceToApply.size);
	}

	setDeliveryType(type: DeliveryType | null): void {
		sessionStore.update(state => ({ ...state, selectedDeliveryType: type }));
	}

	//TODO: Проверить и оставить один из типов StoredOrderItemDto | StoredOrderItem
	addReplayItems(replayItems: (PastOrderItemDto | StoredOrderItem)[]): void {
		//prepare items to add
		replayItems.forEach(replayItem => {
			const basketItem: BasketItem = {} as BasketItem;

			const itemInfo = this.menuRepository.getItemBySizeSku(replayItem.sku);
			if (replayItem.name === $localize`:@@Delivery:Delivery`) { return; }
			if (!itemInfo) {
				void this.router.navigate(['/']);
				this.statusMessage.setStatusMessage({
					title: $localize`:@@MessageModalTitleError:Error`,
					message: $localize`:@@ResponseErrorCreateNewOrder:Sorry, some items from the menu are not available.
          Create a new order please.`,
					type: 'error'
				});
				return;
			}

			basketItem.item = itemInfo.item;
			basketItem.size = itemInfo.size;
			basketItem.count = replayItem.quantity;

			basketItem.modifiers = [];
			replayItem.modifiers.forEach(modifier => {
				const findMod = this.getModifierBySku(itemInfo.size, modifier.sku);
				if (!findMod) { return }
				findMod.quantity = modifier.quantity;
				basketItem.modifiers.push(findMod);
			});

			this.basketRepository.addItem(basketItem);
			void this.router.navigate(['/ordering']);
		});
	}

	private getModifierBySku(size: MenuItemSizeDto, sku: string): Modifier | null {
		let findModifier = null;
		size.itemModifierGroups.forEach(group => {
			group.items.forEach(modifier => {
				if (modifier.sku == sku) {
					findModifier = modifier;
				}
			});
		});

		return findModifier;
	}

	getUnavailableItems(): Observable<BasketItem[]> {
		return combineLatest([
			this.menuRepository.stopListItems$,
			this.basketRepository.items$,
			this.configRepository.activeOutletId$
		]).pipe(
			map(([stopList, basketItems, activeOutletId]) => {
				const unavailableItems: BasketItem[] = [];
				const stopListMap: Map<string, number> = new Map<string, number>();
				basketItems.forEach(basketItem => {
					if (!basketItem.item.itemId || basketItem.item.balance === undefined) {
						return
					}

					if (stopList.has(basketItem.item.itemId)) {
						const balance = stopList.get(basketItem.item.itemId) as number;
						if (balance >= basketItem.count) {
							return
						}
						unavailableItems.push({ ...basketItem, balance: balance });
						stopListMap.set(basketItem.itemKey, balance);
						return;
					}

					const outletPrice = basketItem.size.prices.find(p => p.storeId == activeOutletId);
					if (!outletPrice || outletPrice.price == null) {
						unavailableItems.push({ ...basketItem, balance: 0 });
						stopListMap.set(basketItem.itemKey, 0);
						return;
					}

					if (basketItem.modifiers) {
						const modifiersAreAvailable = basketItem.modifiers.every(mod => {
							if (!mod.prices) { return false }
							return mod.prices.find(p => p.storeId == activeOutletId)?.price !== null
						});

						if (!modifiersAreAvailable) {
							unavailableItems.push({ ...basketItem, balance: 0 });
							stopListMap.set(basketItem.itemKey, 0);
							return;
						}
					}

				});

				sessionStore.update(state => ({ ...state, balanceToApply: stopListMap }));

				return unavailableItems;
			})
		)
	}

	applyBalance(): void {
		const balanceToApply = sessionStore.query((state) => state.balanceToApply);
		// TODO: batching
		balanceToApply.forEach((value, key) => {
			if (value == 0) {
				this.basketRepository.removeItem(key);
			} else {
				this.basketRepository.updateCount(key, value);
			}
		});
		sessionStore.update(state => ({ ...state, balanceToApply: new Map<string, number>() }));
	}

	checkCoupon(couponNumber: string): Observable<boolean> {
		sessionStore.update(updateRequestStatus('couponStatusFetching', 'pending'));

		return this.orderService.v1OrderCouponCheckPost$Json({
			body: {
				couponNumber: couponNumber,
				storeId: this.configRepository.activeOutletId
			}
		}).pipe(
			map(response => {
				if (response.error) {
					throw new Error('coupon is not found');
				}
				return response.result
			}),
			tap(() => sessionStore.update(updateRequestStatus('couponStatusFetching', 'success'))),
			catchError(error => {
				this.errorService.handleError(error);
				sessionStore.update(updateRequestStatus('couponStatusFetching', 'error', error));
				throw new Error('coupon is not found');
			})
		)
	}

	checkBasketAvailable() {
		const basketItems = this.basketRepository.items;
		const itemNames: string[] = [];

		basketItems.forEach(item => {
			if (!this.menuRepository.isCategoryScheduleAvailable(item.item.categoryId)) {
				itemNames.push(item.item.name);
				this.basketRepository.removeItem(item.itemKey);
			}
		})

		if (!itemNames.length) { return }

		this.statusMessage.setStatusMessage({
			title: $localize`:@@MessageModalTitleWarning:Warning`,
			message: [
				$localize`:@@MessageItemScheduleNotAvailable:Unfortunately, we do not prepare this dish at the time you selected for delivery. Try choosing something else.`,
				...itemNames
			],
			type: 'warning'
		});
	}

	removeBasket(): void {
		sessionStore.update(
			state => ({
				...state,
				isMustProcess: true,
				isBonusPayment: false,
				tablePosId: null,
				isTableFromLink: false,
				tableId: null,
				numberOfGuests: 1,
				preprocessedBasketId: null,
				onlinePaymentOrderId: null,
				basket: null,
			}),
			updateRequestStatus('basketPreprocessing', 'idle'),
		);

		this.basketRepository.deleteAll();
	}

  saveBasket(
    paymentType: PaymentType | null,
    customer: CustomerDto | null,
    coupon: string | null,
    token: string | null | undefined,
    isAsap: boolean | null = null,
  ): void {
    const storedIsBonusPayment = sessionStore.query(state => state.isBonusPayment);
    const bonusPaymentSum = sessionStore.query(state => state.maxBonusPayment);
    const basketId = sessionStore.query(state => state.preprocessedBasketId);

    if (customer) {
      customer.email = customer.email?.toLowerCase();
      customer.token = token;
    }

    const basket: ProcessBasketRequestDto = {
      id: basketId,
      storeId: this.configRepository.activeOutletId,
      customer: customer,
      selectPayVal: paymentType ?? this.configRepository.activePaymentsType,
      selectedDeliveryType: this.selectedDeliveryType!,
      bonusPaymentSum : storedIsBonusPayment ? bonusPaymentSum : 0,
      items: []
    };

    basket.isAsap = !!isAsap;

    if(this.configRepository.activeZoneId && this.configRepository.coordinates) {
      basket.coordinates = this.configRepository.coordinates;
      basket.deliveryZoneId = this.configRepository.activeZoneId;
    }

    if (coupon) {
      basket.coupon = coupon;
    }

    sessionStore.update(state => ({...state, basket}));
  }


  updateAll(data: ProcessBasketResponse) {
    this.basketRepository.updateAll(data);
    sessionStore.update(
      state => ({
        ...state,
        maxBonusPayment: data.availableBonusPayment,
        isMustProcess: false,
        preprocessedBasketId: data.basketId,
        dtmTime: data.deliveryTime || null,
      }),
    );
  }

  deliveryAddress$: Observable<AddressDto | null> = sessionStore.pipe(
    select(state => state.deliveryAddress),
    map(address => {
      if(!address){return null}
      const tmpAddress = {...address}

      if(tmpAddress.street){
        tmpAddress.street = tmpAddress.street.replace('улица', 'ул. ').trim();
      }

      return tmpAddress
    })
  );

  get deliveryAddress(): AddressDto | null {
    return sessionStore.query(state => state.deliveryAddress)
  }

  setDeliveryAddress(address: AddressDto | null){
    if(address && !address.street && !address.house){return}
    sessionStore.update(setProp('deliveryAddress', address));
  }

  setFreeItems(freeItems: FreeItem[] | null): void {
    sessionStore.update(setProp('freeItems', freeItems));
  }

  freeItems$: Observable<UIMenuItemDto[] | null> = sessionStore.pipe(
    select(state => state.freeItems),
    map((freeItems: FreeItem[] | null) => {
      if(!freeItems || !freeItems.length){return null}

      const items = this.prepareFreeItems(freeItems);

      return items.length ? items : null;
    }),
  )

  private prepareFreeItems(freeItems: FreeItem[]): UIMenuItemDto[] {
    const items: UIMenuItemDto[] = [];

    freeItems.forEach(freeItem => {
      const item = this.menuRepository.getItem(freeItem.categoryId + freeItem.itemId);
      if(!item){return}

      const freeSizes = this.getFreeItemSizes(freeItem.sizeSkus, item.itemSizes);
      if(!freeSizes.length){return}

      items.push({
        ...item,
        itemSizes: freeSizes
      })
    })

    return items;
  }

  private getFreeItemSizes(sizeSkus: string[], itemSizes: MenuItemSizeDto[]): MenuItemSizeDto[] {
    if(!sizeSkus.length){return itemSizes}
    return itemSizes.filter(size => sizeSkus.includes(size.sku));
  }
}
