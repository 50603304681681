<div class="page">
  <div class="page__header" [ngClass]="{'is-hide': isHideHeader$ | async}">
    <ng-template #headerView></ng-template>
  </div>

  <div class="page__main">
    <router-outlet></router-outlet>
  </div>

  <div class="page__footer">
    <ng-template #footerView></ng-template>
  </div>
</div>

<ng-template #a2hsView></ng-template>

<ng-template *ngIf="isIiko$ | async" #cookiesAlertView></ng-template>

<ng-template #orderTypeChooseModal></ng-template>





<ng-template #statusMessageView></ng-template>
