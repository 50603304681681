/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { GuidDecimalDictionaryGenericResponse } from '../models/guid-decimal-dictionary-generic-response';
import { MenuDtoGenericResponse } from '../models/menu-dto-generic-response';

@Injectable({ providedIn: 'root' })
export class MenuService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1MenuGet()` */
  static readonly V1MenuGetPath = '/v1/menu';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1MenuGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1MenuGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MenuDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MenuService.V1MenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MenuDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1MenuGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1MenuGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<MenuDtoGenericResponse> {
    return this.v1MenuGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MenuDtoGenericResponse>): MenuDtoGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1MenuGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1MenuGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MenuDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MenuService.V1MenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MenuDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1MenuGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1MenuGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<MenuDtoGenericResponse> {
    return this.v1MenuGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MenuDtoGenericResponse>): MenuDtoGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1MenuStopListsStoreIdGet()` */
  static readonly V1MenuStopListsStoreIdGetPath = '/v1/menu/stop-lists/{storeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1MenuStopListsStoreIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1MenuStopListsStoreIdGet$Plain$Response(
    params: {
      storeId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GuidDecimalDictionaryGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MenuService.V1MenuStopListsStoreIdGetPath, 'get');
    if (params) {
      rb.path('storeId', params.storeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidDecimalDictionaryGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1MenuStopListsStoreIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1MenuStopListsStoreIdGet$Plain(
    params: {
      storeId: number;
    },
    context?: HttpContext
  ): Observable<GuidDecimalDictionaryGenericResponse> {
    return this.v1MenuStopListsStoreIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuidDecimalDictionaryGenericResponse>): GuidDecimalDictionaryGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1MenuStopListsStoreIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1MenuStopListsStoreIdGet$Json$Response(
    params: {
      storeId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GuidDecimalDictionaryGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MenuService.V1MenuStopListsStoreIdGetPath, 'get');
    if (params) {
      rb.path('storeId', params.storeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidDecimalDictionaryGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1MenuStopListsStoreIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1MenuStopListsStoreIdGet$Json(
    params: {
      storeId: number;
    },
    context?: HttpContext
  ): Observable<GuidDecimalDictionaryGenericResponse> {
    return this.v1MenuStopListsStoreIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<GuidDecimalDictionaryGenericResponse>): GuidDecimalDictionaryGenericResponse => r.body)
    );
  }

}
