/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AddressChoiceArrayGenericResponse } from '../models/address-choice-array-generic-response';
import { CityArrayGenericResponse } from '../models/city-array-generic-response';
import { DeliveryAddressHintsRequest } from '../models/delivery-address-hints-request';
import { DeliveryAddressHintsResponseGenericResponse } from '../models/delivery-address-hints-response-generic-response';
import { DeliveryZoneForOrderRequest } from '../models/delivery-zone-for-order-request';
import { DeliveryZoneRequest } from '../models/delivery-zone-request';
import { DeliveryZoneResponseGenericResponse } from '../models/delivery-zone-response-generic-response';

@Injectable({ providedIn: 'root' })
export class AddressService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1AddressStreetsStoreIdGet()` */
  static readonly V1AddressStreetsStoreIdGetPath = '/v1/address/streets/{storeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1AddressStreetsStoreIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1AddressStreetsStoreIdGet$Plain$Response(
    params: {
      storeId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CityArrayGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AddressService.V1AddressStreetsStoreIdGetPath, 'get');
    if (params) {
      rb.path('storeId', params.storeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CityArrayGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1AddressStreetsStoreIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1AddressStreetsStoreIdGet$Plain(
    params: {
      storeId: number;
    },
    context?: HttpContext
  ): Observable<CityArrayGenericResponse> {
    return this.v1AddressStreetsStoreIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CityArrayGenericResponse>): CityArrayGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1AddressStreetsStoreIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1AddressStreetsStoreIdGet$Json$Response(
    params: {
      storeId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CityArrayGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AddressService.V1AddressStreetsStoreIdGetPath, 'get');
    if (params) {
      rb.path('storeId', params.storeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CityArrayGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1AddressStreetsStoreIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1AddressStreetsStoreIdGet$Json(
    params: {
      storeId: number;
    },
    context?: HttpContext
  ): Observable<CityArrayGenericResponse> {
    return this.v1AddressStreetsStoreIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CityArrayGenericResponse>): CityArrayGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1AddressAddressesByPostcodeGet()` */
  static readonly V1AddressAddressesByPostcodeGetPath = '/v1/address/addresses-by-postcode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1AddressAddressesByPostcodeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1AddressAddressesByPostcodeGet$Plain$Response(
    params?: {
      postCode?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AddressChoiceArrayGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AddressService.V1AddressAddressesByPostcodeGetPath, 'get');
    if (params) {
      rb.query('postCode', params.postCode, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressChoiceArrayGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1AddressAddressesByPostcodeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1AddressAddressesByPostcodeGet$Plain(
    params?: {
      postCode?: string;
    },
    context?: HttpContext
  ): Observable<AddressChoiceArrayGenericResponse> {
    return this.v1AddressAddressesByPostcodeGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressChoiceArrayGenericResponse>): AddressChoiceArrayGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1AddressAddressesByPostcodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1AddressAddressesByPostcodeGet$Json$Response(
    params?: {
      postCode?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AddressChoiceArrayGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AddressService.V1AddressAddressesByPostcodeGetPath, 'get');
    if (params) {
      rb.query('postCode', params.postCode, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressChoiceArrayGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1AddressAddressesByPostcodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1AddressAddressesByPostcodeGet$Json(
    params?: {
      postCode?: string;
    },
    context?: HttpContext
  ): Observable<AddressChoiceArrayGenericResponse> {
    return this.v1AddressAddressesByPostcodeGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressChoiceArrayGenericResponse>): AddressChoiceArrayGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1AddressGetDeliveryZoneForOrderPost()` */
  static readonly V1AddressGetDeliveryZoneForOrderPostPath = '/v1/address/get-delivery-zone-for-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1AddressGetDeliveryZoneForOrderPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1AddressGetDeliveryZoneForOrderPost$Plain$Response(
    params?: {
      body?: DeliveryZoneForOrderRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DeliveryZoneResponseGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AddressService.V1AddressGetDeliveryZoneForOrderPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeliveryZoneResponseGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1AddressGetDeliveryZoneForOrderPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1AddressGetDeliveryZoneForOrderPost$Plain(
    params?: {
      body?: DeliveryZoneForOrderRequest
    },
    context?: HttpContext
  ): Observable<DeliveryZoneResponseGenericResponse> {
    return this.v1AddressGetDeliveryZoneForOrderPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryZoneResponseGenericResponse>): DeliveryZoneResponseGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1AddressGetDeliveryZoneForOrderPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1AddressGetDeliveryZoneForOrderPost$Json$Response(
    params?: {
      body?: DeliveryZoneForOrderRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DeliveryZoneResponseGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AddressService.V1AddressGetDeliveryZoneForOrderPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeliveryZoneResponseGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1AddressGetDeliveryZoneForOrderPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1AddressGetDeliveryZoneForOrderPost$Json(
    params?: {
      body?: DeliveryZoneForOrderRequest
    },
    context?: HttpContext
  ): Observable<DeliveryZoneResponseGenericResponse> {
    return this.v1AddressGetDeliveryZoneForOrderPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryZoneResponseGenericResponse>): DeliveryZoneResponseGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1AddressGetDeliveryZonePost()` */
  static readonly V1AddressGetDeliveryZonePostPath = '/v1/address/get-delivery-zone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1AddressGetDeliveryZonePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1AddressGetDeliveryZonePost$Plain$Response(
    params?: {
      body?: DeliveryZoneRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DeliveryZoneResponseGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AddressService.V1AddressGetDeliveryZonePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeliveryZoneResponseGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1AddressGetDeliveryZonePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1AddressGetDeliveryZonePost$Plain(
    params?: {
      body?: DeliveryZoneRequest
    },
    context?: HttpContext
  ): Observable<DeliveryZoneResponseGenericResponse> {
    return this.v1AddressGetDeliveryZonePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryZoneResponseGenericResponse>): DeliveryZoneResponseGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1AddressGetDeliveryZonePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1AddressGetDeliveryZonePost$Json$Response(
    params?: {
      body?: DeliveryZoneRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DeliveryZoneResponseGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AddressService.V1AddressGetDeliveryZonePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeliveryZoneResponseGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1AddressGetDeliveryZonePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1AddressGetDeliveryZonePost$Json(
    params?: {
      body?: DeliveryZoneRequest
    },
    context?: HttpContext
  ): Observable<DeliveryZoneResponseGenericResponse> {
    return this.v1AddressGetDeliveryZonePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryZoneResponseGenericResponse>): DeliveryZoneResponseGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1AddressHintsPost()` */
  static readonly V1AddressHintsPostPath = '/v1/address/hints';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1AddressHintsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1AddressHintsPost$Plain$Response(
    params?: {
      body?: DeliveryAddressHintsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DeliveryAddressHintsResponseGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AddressService.V1AddressHintsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeliveryAddressHintsResponseGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1AddressHintsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1AddressHintsPost$Plain(
    params?: {
      body?: DeliveryAddressHintsRequest
    },
    context?: HttpContext
  ): Observable<DeliveryAddressHintsResponseGenericResponse> {
    return this.v1AddressHintsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryAddressHintsResponseGenericResponse>): DeliveryAddressHintsResponseGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1AddressHintsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1AddressHintsPost$Json$Response(
    params?: {
      body?: DeliveryAddressHintsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DeliveryAddressHintsResponseGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AddressService.V1AddressHintsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeliveryAddressHintsResponseGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1AddressHintsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1AddressHintsPost$Json(
    params?: {
      body?: DeliveryAddressHintsRequest
    },
    context?: HttpContext
  ): Observable<DeliveryAddressHintsResponseGenericResponse> {
    return this.v1AddressHintsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryAddressHintsResponseGenericResponse>): DeliveryAddressHintsResponseGenericResponse => r.body)
    );
  }

}
