/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BooleanGenericResponse } from '../models/boolean-generic-response';
import { CheckCouponRequest } from '../models/check-coupon-request';
import { OrderCreationRequestDto } from '../models/order-creation-request-dto';
import { OrderCreationResponseDtoGenericResponse } from '../models/order-creation-response-dto-generic-response';
import { ProcessBasketRequestDto } from '../models/process-basket-request-dto';
import { ProcessBasketResponseGenericResponse } from '../models/process-basket-response-generic-response';

@Injectable({ providedIn: 'root' })
export class OrderService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1OrderNewPost()` */
  static readonly V1OrderNewPostPath = '/v1/order/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1OrderNewPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1OrderNewPost$Plain$Response(
    params?: {
      body?: OrderCreationRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OrderCreationResponseDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, OrderService.V1OrderNewPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderCreationResponseDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1OrderNewPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1OrderNewPost$Plain(
    params?: {
      body?: OrderCreationRequestDto
    },
    context?: HttpContext
  ): Observable<OrderCreationResponseDtoGenericResponse> {
    return this.v1OrderNewPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderCreationResponseDtoGenericResponse>): OrderCreationResponseDtoGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1OrderNewPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1OrderNewPost$Json$Response(
    params?: {
      body?: OrderCreationRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OrderCreationResponseDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, OrderService.V1OrderNewPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderCreationResponseDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1OrderNewPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1OrderNewPost$Json(
    params?: {
      body?: OrderCreationRequestDto
    },
    context?: HttpContext
  ): Observable<OrderCreationResponseDtoGenericResponse> {
    return this.v1OrderNewPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderCreationResponseDtoGenericResponse>): OrderCreationResponseDtoGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1OrderBasketProcessPost()` */
  static readonly V1OrderBasketProcessPostPath = '/v1/order/basket/process';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1OrderBasketProcessPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1OrderBasketProcessPost$Plain$Response(
    params?: {
      body?: ProcessBasketRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProcessBasketResponseGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, OrderService.V1OrderBasketProcessPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessBasketResponseGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1OrderBasketProcessPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1OrderBasketProcessPost$Plain(
    params?: {
      body?: ProcessBasketRequestDto
    },
    context?: HttpContext
  ): Observable<ProcessBasketResponseGenericResponse> {
    return this.v1OrderBasketProcessPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessBasketResponseGenericResponse>): ProcessBasketResponseGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1OrderBasketProcessPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1OrderBasketProcessPost$Json$Response(
    params?: {
      body?: ProcessBasketRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProcessBasketResponseGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, OrderService.V1OrderBasketProcessPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessBasketResponseGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1OrderBasketProcessPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1OrderBasketProcessPost$Json(
    params?: {
      body?: ProcessBasketRequestDto
    },
    context?: HttpContext
  ): Observable<ProcessBasketResponseGenericResponse> {
    return this.v1OrderBasketProcessPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessBasketResponseGenericResponse>): ProcessBasketResponseGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1OrderCancelIdPost()` */
  static readonly V1OrderCancelIdPostPath = '/v1/order/cancel/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1OrderCancelIdPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1OrderCancelIdPost$Plain$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BooleanGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, OrderService.V1OrderCancelIdPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1OrderCancelIdPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1OrderCancelIdPost$Plain(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<BooleanGenericResponse> {
    return this.v1OrderCancelIdPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanGenericResponse>): BooleanGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1OrderCancelIdPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1OrderCancelIdPost$Json$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BooleanGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, OrderService.V1OrderCancelIdPostPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1OrderCancelIdPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1OrderCancelIdPost$Json(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<BooleanGenericResponse> {
    return this.v1OrderCancelIdPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanGenericResponse>): BooleanGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1OrderCouponCheckPost()` */
  static readonly V1OrderCouponCheckPostPath = '/v1/order/coupon/check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1OrderCouponCheckPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1OrderCouponCheckPost$Plain$Response(
    params?: {
      body?: CheckCouponRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BooleanGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, OrderService.V1OrderCouponCheckPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1OrderCouponCheckPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1OrderCouponCheckPost$Plain(
    params?: {
      body?: CheckCouponRequest
    },
    context?: HttpContext
  ): Observable<BooleanGenericResponse> {
    return this.v1OrderCouponCheckPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanGenericResponse>): BooleanGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1OrderCouponCheckPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1OrderCouponCheckPost$Json$Response(
    params?: {
      body?: CheckCouponRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BooleanGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, OrderService.V1OrderCouponCheckPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1OrderCouponCheckPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v1OrderCouponCheckPost$Json(
    params?: {
      body?: CheckCouponRequest
    },
    context?: HttpContext
  ): Observable<BooleanGenericResponse> {
    return this.v1OrderCouponCheckPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanGenericResponse>): BooleanGenericResponse => r.body)
    );
  }

}
